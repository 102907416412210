import styled from 'styled-components'

const Camera = (props) => {
  return (
    <Section>
      <Video>
        <video id="video" width="320" height="240" autoPlay />
      </Video>
    </Section>
  )
}

/*
<Icon>
  <Edit color="#3F3F3F" size={0.8} />
</Icon>
*/

const Section = styled.div`
  font-size: 14px;

  width: 100%;
  margin: 0 auto;
  color: ${(p) => p.theme.color_level.green.high};
  background-color: white;
  border: 1px solid ${(p) => p.theme.color.border};
  border-radius: 8px;
  position: relative;

  &.disable {
    background-color: ${(p) => p.theme.color.disable};
  }
`

const Video = styled.div`
  width: 100%;
`

export default Camera
