import member from './Member'
import lang from './Lang'
import message from './Message'
import province from './Province'
import consent from './Consent'
import account from './Account'
import company from './Company'
import dashboard from './Dashboard'
import holiday from './Holiday'
import department from './Department'
import employee from './Employee'
import doc from './Document'
import team from './Team'
import task from './Task'
import phone from './Phone'
// leave request
import leaveReq from './LeaveReq'
import leaveBalance from './LeaveReq/Balance'
import leaveConfig from './LeaveReq/Config'
import leaveType from './LeaveReq/Type'
import leaveSummary from './LeaveReq/Summary'
// reimbursement
import reimburse from './Reimburse'
import reimburseConfig from './Reimburse/Config'
import costCenter from './Reimburse/CostCenter'
// attendance
import attendance from './Attendance'
import shift from './Attendance/Shift'
import attendanceTeam from './Attendance/Team'
// payroll
import payroll from './Payroll'
// overtime
import overtime from './Overtime'
import overtimeConfig from './Overtime/Config'
// feed
import feed from './Feed'
import timesheet from './Timesheet'
import timesheetManager from './Timesheet/Manager'
import timesheetTeam from './Timesheet/Team'

/* eslint-disable */
export default {
  member,
  lang,
  message,
  account,
  company,
  province,
  consent,
  holiday,
  dashboard,
  department,
  team,
  employee,
  phone,
  task,
  leaveReq,
  leaveBalance,
  leaveConfig,
  leaveType,
  leaveSummary,
  reimburse,
  reimburseConfig,
  costCenter,
  document: doc,
  attendance,
  attendanceTeam,
  overtime,
  overtimeConfig,
  shift,
  payroll,
  feed,
  timesheet,
  timesheetManager,
  timesheetTeam,
}
/* eslint-enable */
