import styled from 'styled-components'

import { PrimaryBtn, Btn } from 'components/button'

import BaseDialog from './BaseDialog'

const ConfirmDialog = (props) => {
  const {
    visible,
    loading,
    image,
    title,
    message,
    cancel,
    confirm,
    onClose,
    onConfirm,
  } = props

  const imageItem = image ? <Image>{image}</Image> : undefined
  return (
    <BaseDialog visible={visible} onClose={onClose}>
      <Content>
        {imageItem}
        <Title>{title}</Title>
        <Message>{message}</Message>
        <Footer>
          <PrimaryBtn loading={loading} text={confirm} onClick={onConfirm} />
          <Line />
          <Btn text={cancel} onClick={onClose} />
        </Footer>
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 100%;
  min-width: 320px;
  text-align: center;

  ${(p) => p.theme.media.mobile} {
    min-width: 300px;
  }

  ${(p) => p.theme.media.mobile_xs} {
    min-width: 250px;
  }
`

const Image = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
`

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
  color: ${(props) => props.theme.color.black};
`

const Message = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.color_level.grey.high};
`

const Footer = styled.div`
  width: 100%;
  padding-top: 16px;
`

const Line = styled.div`
  width: 100%;
  padding-top: 8px;
`

export default ConfirmDialog
