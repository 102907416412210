import { runInAction } from 'mobx'

import { http, format, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const timesheetUrl = `${config.api}/v1/user/timesheet`

let state

export class TimesheetManager extends BaseStore {
  constructor() {
    super()
    this.observable({
      project_menu: [],
      project_list: [],
      summary: {
        minute_total: 0,
        weekly_list: [],
        summary_activity_list: [],
        activity_list: [],
        member_list: [],
        summary_activity: {
          label_list: [],
          value_list: [],
        },
      },
      timesheet: {
        summary: {
          minute_total: 5000,
          activity_list: [],
        },
        timesheet_list: [],
      },
      member_menu: [],
      category: {
        all_employee: 'no',
        active_total: 0,
        member_list: [],
      },
    })

    state = this
  }

  async getProjectList() {
    const store = this.toJS()
    if (store.project_list.length > 0) {
      return
    }

    const url = `${timesheetUrl}/manager/project`
    const res = await http.get(url)
    const list = res.body || []

    const project_menu = list.map((it) => {
      const { ts_category_id, cost_center_name, category_name } = it
      return {
        name: `${category_name} (${cost_center_name})`,
        value: ts_category_id,
      }
    })
    runInAction(() => {
      state.project_menu = project_menu
      state.project_list = list
    })
  }

  async getCategory(categoryId) {
    const url = `${timesheetUrl}/manager/category/${categoryId}`
    const res = await http.get(url)
    const data = res.body || {}

    const member_list = data.member_list || []

    let active_total = 0
    const member_menu = member_list.map((it) => {
      if (it.status === 'active') ++active_total
      return {
        name: format.toUsername(it),
        value: it.user_id,
      }
    })

    data.active_total = active_total
    runInAction(() => {
      state.category = data
      state.member_menu = member_menu
    })
  }

  async updateCategory(categoryId, params) {
    const { status, all_employee } = params
    const json = { status, all_employee }
    const url = `${timesheetUrl}/manager/category/${categoryId}`
    const res = await http.put(url, { json })
    const data = res.body || {}

    const { category } = this.toJS()
    category.status = data.status
    category.all_employee = data.all_employee

    runInAction(() => {
      state.category = category
    })
  }

  setMemberStatusList(userId, status) {
    const { category } = this.toJS()
    const member_list = category.member_list || []
    const i = member_list.findIndex((it) => it.user_id === userId)
    if (i !== -1) {
      member_list[i].status = status
      category.member_list = member_list
      if (status === 'active') {
        category.active_total++
      } else {
        category.active_total--
      }
      runInAction(() => {
        state.category = category
      })
    }
  }

  async addUser(categoryId, userId) {
    const url = `${timesheetUrl}/manager/category/${categoryId}/${userId}`
    await http.post(url)

    this.setMemberStatusList(userId, 'active')
  }

  async removeUser(categoryId, userId) {
    const url = `${timesheetUrl}/manager/category/${categoryId}/${userId}`
    await http.delete(url)

    this.setMemberStatusList(userId, 'inactive')
  }

  async getTimesheet(categoryId, { start_at } = {}) {
    const dateTxt = start_at.format('DDMMYY')
    const url = `${timesheetUrl}/manager/timesheet/${categoryId}/${dateTxt}`
    const res = await http.get(url)
    const { summary, timesheet_list = [] } = res.body || {}

    const member_menu = timesheet_list.map((it) => {
      return {
        name: format.toUsername(it),
        value: it.user_id,
      }
    })

    runInAction(() => {
      state.member_menu = member_menu
      state.timesheet = {
        summary,
        timesheet_list,
      }
    })
  }

  async getSummary(categoryId, { start_at, finish_at } = {}) {
    let url = `${timesheetUrl}/manager/summary/${categoryId}`
    if (start_at && finish_at) {
      const startTxt = start_at.format('DDMMYY')
      const finishTxt = finish_at.format('DDMMYY')
      url += `?start=${startTxt}&&finish=${finishTxt}`
    }
    const res = await http.get(url)
    const {
      minute_total,
      activity_list,
      summary_activity_list,
      monthly_list,
      member_list,
    } = res.body || {}

    const activityList = activity_list.map((it) => {
      return {
        text: it.activity_name,
        minute_total: it.minute_total,
      }
    })

    const memberList = member_list.map((it) => {
      return {
        text: format.toUsername(it),
        minute_total: it.minute_total,
      }
    })

    const summary_activity = {
      label_list: [],
      value_list: [],
    }

    for (const item of summary_activity_list) {
      const { activity_name, value } = item
      const name = `${activity_name}: ${value}%`
      summary_activity.label_list.push(name)
      summary_activity.value_list.push(value || 0)
    }

    const summary_monthly = {
      label_list: [],
      value_list: [],
    }
    for (const item of monthly_list) {
      summary_monthly.label_list.push(item.text)
      summary_monthly.value_list.push(item.manday || 0)
    }

    runInAction(() => {
      state.summary = {
        id: timer.getUnix(),
        minute_total: minute_total || 0,
        summary_monthly,

        summary_activity,
        activity_list: activityList,
        member_list: memberList,
      }
    })
  }
}

const timesheetManager = new TimesheetManager()
export default timesheetManager
