const color = {
  red: '#F36273',
  yellow: '#F7E293',
  blue: '#305FED',
  green: '#2a9c9d',
  light_green: '#85E3D1',
  black: '#1F1F1F',
  grey: '#8A8A8A',
  white: 'white',

  success: '#00C767',
  error: '#DE001A',
  warning: '#EDAE30',
  action: '#305FED',
  disable: '#DCDCDC',
  backgroundColor: '#F6F7FB',
  border: '#cccccc',
}

const input = {
  disable: '#F5F6F9',
  border: '#cccccc',
}

const leave_category = {
  sick: { color: 'white', bg: '#2A9C9D' },
  business: { color: 'white', bg: '#85E3D1' },
  information: { color: '#333333', bg: '#F7E293' },
  vacation: { color: 'white', bg: '#F36273' },
  other: { color: '#333333', bg: '#82C3FF' },
}

const color_level = {
  red: {
    high: '#D70004',
    medium: '#F36273',
    low: '#F9C5CE',
  },

  yellow: {
    high: '#F2D152',
    medium: '#EDAE30',
    low: '#fadda4',
  },

  blue: {
    high: '#134EE4',
    medium: '#305FED',
    low: '#6A89F7',
  },

  green: {
    high: '#00B377',
    medium: '#00C767',
    low: '#E9FFFB',
    lowest: '#e6fef1',
  },

  grey: {
    dark: '#1F1F1F',
    high: '#3F3F3F',
    medium: '#626262',
    low: '#CCCCCC',
    lowest: '#ececec',
  },
}

const media = {
  mobile_xs: '@media screen and (max-width: 350px)',
  mobile: '@media screen and (max-width: 500px)',
  tablet: '@media screen and (max-width: 800px)',
  pc: '@media screen and (max-width: 1024px)',
  wirescreen: '@media screen and (max-width: 1440px)',
}

const theme = {
  max: '1100px',
  app_max: '600px',
  color,
  color_level,
  leave_category,
  input,
  media,
}

export default theme
