import { runInAction } from 'mobx'

import { http, timer, format } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const timesheetUrl = `${config.api}/v1/user/timesheet`

let state

export class TimesheetTeam extends BaseStore {
  constructor() {
    super()
    this.observable({
      team_list: [],
      user_menu: [],
    })

    state = this
  }

  async getTimesheet({ start_at } = {}) {
    const sTxt = start_at.format('DDMMYY')
    const url = `${timesheetUrl}/report/leader/${sTxt}`
    const res = await http.get(url)
    const list = res.body || []

    const user_menu = []
    for (const user of list) {
      const { user_id } = user
      const date_list = user.date_list || []
      user_menu.push({
        name: format.toUsername(user),
        value: user_id,
      })
      for (const date of date_list) {
        date.work_at = timer.get(date.work_at)
      }
    }

    runInAction(() => {
      state.team_list = list
      state.user_menu = user_menu
    })
  }
}

const timesheetTeam = new TimesheetTeam()
export default timesheetTeam
