import { Edit } from 'icon'

import BaseBtn from './BaseBtn'

const EditBtn = (props) => {
  const { disabled, onClick, loading, tag } = props

  return (
    <BaseBtn
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      tag={tag}
      icon={<Edit color="#3F3F3F" size={0.7} />}
      color={'white'}
      bg_color={'#CCCCCC'}
      border_color={'#CCCCCC'}
    />
  )
}

export default EditBtn
