import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const shift_url = `${config.api}/v1/user/attendance`

let state
export class Shift extends BaseStore {
  constructor() {
    super()
    this.observable({
      setting: {},
      shift_list: [],
    })

    state = this
  }

  async getShiftList({ date } = {}) {
    const url = `${shift_url}/shift/${date.toISOString()}`
    const res = await http.get(url)
    const { setting, shift_list } = res.body || {}

    runInAction(() => {
      state.setting = setting || {}
      state.shift_list = shift_list || []
    })
  }

  async updateShift({ index, date } = {}, json = {}) {
    const url = `${shift_url}/shift/${date.toISOString()}`
    await http.put(url, { json })

    const shift_list = this.toJS().shift_list || []

    shift_list[index] = json
    runInAction(() => {
      state.shift_list = shift_list
    })
  }
}

export default new Shift()
