import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const feed_url = `${config.api}/v1/user/feed`

const textA = `
สวัสดีครับ ขออนุญาตหางานครับ เพิ่งจบการศึกษาจากคณะวิศวกรรมศาสตร์ มหาวิทยาลัยเชียงใหม่ครับ
#ขอเกริ่นก่อนนะครับ ว่ายังไม่ผ่านการเกณฑ์ทหารและจะเข้ารับการเกณฑ์ทหารต้นเดือนเมษาปีหน้าครับ
ประสบการณ์ฝึกงาน: Full-Stack 2 เดือนครับ และมีทำโปรเจคร่วมกับทางมหาวิทยาลัยครับ
ตำแหน่งที่สนใจ: Back-end developer/Front-end developer/FullStack Developer ครับ
รูปแบบการทำงาน: Onsite/Hybrid/WFH (Onsite/Hybrid ในจังหวัดเชียงใหม่จะดีมากครับ)
Skill:
Programming Languages
- JavaScript
- TypeScript
- Go
- Python
Frameworks & Libraries
Backend
- Node.js (Express, Fastify)
- Bun (Elysia)
- Go (Fiber)
- Python (Flask)
Frontend
- React.js
- Next.js
- Vue.js
- Svelte
Database
- SQL (MySQL, PostgreSQL)
- MongoDB
Other
- GitHub
- Postman
- Docker
- Tailwind
- DaisyUI
- Bootstrap
เงินเดือนที่คาดหวัง 18,000++ บาท
ยินดีพร้อมเรียนรู้สิ่งใหม่ ๆ และสามารถเริ่มงานได้ทันทีครับ
#nodejs #reactjs
`

const postA = {
  user_id: undefined,
  name: 'Park',
  surname: 'Shin Hye',
  img_url: null,
  status: 'none',
  text: textA,
  content_list: [],
  img_list: [
    {
      img_url:
        'https://ik.imagekit.io/tvlk/blog/2022/02/01.-Banff.jpg?tr=dpr-2,w-675',
    },
  ],
  text_color: 'black',
  background_color: 'white',
  background_url: null,
  post_at: timer.get().subtract(10, 'minutes'),
  liked_status: 'none',
  liked_list: [],
}

const postB = {
  user_id: undefined,
  name: 'Song',
  surname: 'Hye Kyo',
  img_url:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/ELLE_TAIWAN_Seo_Yea-ji_2020.jpg/1200px-ELLE_TAIWAN_Seo_Yea-ji_2020.jpg',
  status: 'none',
  text: 'Good Morning',
  content_list: [],
  img_list: [
    {
      img_url:
        'https://s359.kapook.com/r/600/auto/pagebuilder/0da1a80c-061d-4af4-87b2-bd4b993d46b4.jpg',
    },
    { img_url: 'https://wallpapercave.com/wp/wp4283981.jpg' },
  ],
  text_color: 'black',
  background_color: 'white',
  background_url: null,
  post_at: timer.get().subtract(6, 'hours'),
  liked_status: 'love',
  liked_list: [],
}

const postC = {
  name: 'Bea',
  surname: 'Szy',
  img_url:
    'https://qph.cf2.quoracdn.net/main-qimg-c407524ad15da434ce48d3015a2b05e5-lq',
  status: 'none',
  text: 'This is a good moment',
  content_list: [],
  img_list: [
    {
      img_url:
        'https://media1.thrillophilia.com/filestore/1192v555js2qpk3g4wdrud2b39ue_img2.jpg',
    },
    {
      img_url:
        'https://s359.kapook.com/r/600/auto/pagebuilder/0da1a80c-061d-4af4-87b2-bd4b993d46b4.jpg',
    },
    { img_url: 'https://wallpapercave.com/wp/wp4283981.jpg' },
  ],
  text_color: 'black',
  background_color: 'white',
  background_url: null,
  post_at: timer.get().subtract(1, 'days'),
  liked_status: 'wow',
  liked_list: [],
}

const postD = {
  // eslint-disable-next-line max-len
  text: 'ราชกิจจานุเบกษา ได้ประกาศพระราชกฤษฎีกา ให้มีการเลือกสมาชิกวุฒิสภา พ.ศ. 2567 วันที่ 11 พฤษภาคม 2567 ผู้สื่อข่าวรายงานว่า ราชกิจจานุเบกษา เผยแพร่พระราชกฤษฎีกา ให้มีการเลือกสมาชิกวุฒิสภา พ.ศ. 2567 พระบาทสมเด็จพระปรเมนทรรามาธิบดีศรีสินทรมหาวชิราลงกรณ พระวชิรเกล้าเจ้าอยู่หัว ให้ไว้ ณ วันที่ 28 เมษายน พ.ศ. 2567 เป็นปีที่ 9 ในรัชกาลปัจจุบัน...',
  img_list: [
    {
      img_url: 'https://inwfile.com/s-cv/s8oxof.jpg',
    },
    {
      img_url:
        'https://w0.peakpx.com/wallpaper/177/378/HD-wallpaper-girl-generation-south-korea-models-female-south-korea-beautiful-asians-sexy.jpg',
    },
    {
      img_url: 'https://www.9mot.com/wp-content/uploads/2017/05/tips.jpg',
    },
    {
      img_url:
        'https://img.freepik.com/premium-photo/beautiful-girl-dress-is-looking-camera-with-her-back-turned_579873-31284.jpg?size=626&ext=jpg&ga=GA1.1.2082370165.1715558400&semt=ais_user',
    },
  ],
  post_at: timer.get().subtract(15, 'days'),
  liked_status: 'liked',
  liked_list: [],
}

const postE = {
  // eslint-disable-next-line max-len
  text: 'ราชกิจจานุเบกษา ได้ประกาศพระราชกฤษฎีกา ให้มีการเลือกสมาชิกวุฒิสภา พ.ศ. 2567 วันที่ 11 พฤษภาคม 2567 ผู้สื่อข่าวรายงานว่า ราชกิจจานุเบกษา เผยแพร่พระราชกฤษฎีกา ให้มีการเลือกสมาชิกวุฒิสภา พ.ศ. 2567 พระบาทสมเด็จพระปรเมนทรรามาธิบดีศรีสินทรมหาวชิราลงกรณ พระวชิรเกล้าเจ้าอยู่หัว ให้ไว้ ณ วันที่ 28 เมษายน พ.ศ. 2567 เป็นปีที่ 9 ในรัชกาลปัจจุบัน...',
  img_list: [
    {
      img_url: 'https://travel.mthai.com/app/uploads/2016/08/AUD_31831.jpg',
    },
  ],
  post_at: timer.get().subtract(45, 'days'),
  liked_status: 'liked',
  liked_list: [],
}

const postF = {
  // eslint-disable-next-line max-len
  text: 'ราชกิจจานุเบกษา ได้ประกาศพระราชกฤษฎีกา ให้มีการเลือกสมาชิกวุฒิสภา พ.ศ. 2567 วันที่ 11 พฤษภาคม 2567 ผู้สื่อข่าวรายงานว่า ราชกิจจานุเบกษา เผยแพร่พระราชกฤษฎีกา ให้มีการเลือกสมาชิกวุฒิสภา พ.ศ. 2567 พระบาทสมเด็จพระปรเมนทรรามาธิบดีศรีสินทรมหาวชิราลงกรณ พระวชิรเกล้าเจ้าอยู่หัว ให้ไว้ ณ วันที่ 28 เมษายน พ.ศ. 2567 เป็นปีที่ 9 ในรัชกาลปัจจุบัน...',
  img_list: [
    {
      img_url:
        'https://www.homeest.com/photo/2014/07/19/homeEST_1405759292_590.jpg',
    },
    {
      img_url:
        'https://p3.toutiaoimg.com/origin/pgc-image/950b017188924ce78c55695204bcacc4?from=pc',
    },
    {
      img_url:
        'https://quietly-image-uploads.s3.amazonaws.com/item_73775_1280px_1eccc5e14df14ac48866862772f07703.jpeg',
    },
    {
      img_url:
        'https://hottestchocolate.com/wp-content/uploads/2022/04/Bae_Suzy-7.jpg',
    },
    {
      img_url:
        'https://www.silavadeeresort.com/wp-content/uploads/2021/03/Beautiful-resort-with-a-panoramic-sea-view-as-far-as-the-eye-can-see-5.jpg',
    },
    {
      img_url:
        'https://www.silavadeeresort.com/wp-content/uploads/2021/03/Beautiful-resort-with-a-panoramic-sea-view-as-far-as-the-eye-can-see-5.jpg',
    },
    {
      img_url:
        'https://gd.image-gmkt.com/PURCHASE-WITH-COUPON-KOREA-SEXY-FASHION-LADIES-BIKINI-MLXL/li/487/974/661974487.g_350-w-et-pj_g.jpg',
    },
  ],
  post_at: timer.get().subtract(55, 'days'),
  liked_status: 'liked',
  liked_list: [],
}

const postG = {
  // eslint-disable-next-line max-len
  text: 'ราชกิจจานุเบกษา ได้ประกาศพระราชกฤษฎีกา ให้มีการเลือกสมาชิกวุฒิสภา พ.ศ. 2567 วันที่ 11 พฤษภาคม 2567 ผู้สื่อข่าวรายงานว่า ราชกิจจานุเบกษา เผยแพร่พระราชกฤษฎีกา ให้มีการเลือกสมาชิกวุฒิสภา พ.ศ. 2567 พระบาทสมเด็จพระปรเมนทรรามาธิบดีศรีสินทรมหาวชิราลงกรณ พระวชิรเกล้าเจ้าอยู่หัว ให้ไว้ ณ วันที่ 28 เมษายน พ.ศ. 2567 เป็นปีที่ 9 ในรัชกาลปัจจุบัน...',
  img_list: [
    {
      img_url:
        'https://www.homeest.com/photo/2014/07/19/homeEST_1405759292_590.jpg',
    },
    {
      img_url:
        'https://www.silavadeeresort.com/wp-content/uploads/2021/03/Beautiful-resort-with-a-panoramic-sea-view-as-far-as-the-eye-can-see-5.jpg',
    },
    {
      img_url:
        'https://quietly-image-uploads.s3.amazonaws.com/item_73775_1280px_1eccc5e14df14ac48866862772f07703.jpeg',
    },
    {
      img_url:
        'https://gd.image-gmkt.com/PURCHASE-WITH-COUPON-KOREA-SEXY-FASHION-LADIES-BIKINI-MLXL/li/487/974/661974487.g_350-w-et-pj_g.jpg',
    },
    {
      img_url:
        'https://www.shutterstock.com/image-photo/portrait-young-beautiful-sexy-asian-600nw-1031318821.jpg',
    },
  ],
  post_at: timer.get().subtract(55, 'days'),
  liked_status: 'liked',
  liked_list: [],
}

const original = {
  user_id: undefined,
  company_id: undefined,
  status: 'none',
  text: '',
  content_list: [],
  img_list: [],
  text_color: 'black',
  background_color: 'white',
  background_url: null,
}

let state

const toDate = (val) => {
  return val ? timer.get(val) : undefined
}

const toFeed = (data = {}) => {
  data.start_at = toDate(data.start_at)

  return data
}

export class Feed extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
      list: [postF, postG],
      Alist: [postA, postB, postC, postD, postE, postF, postG],
    })

    state = this
  }

  reset() {
    runInAction(() => {
      state.doc = cloneDeep(original)
    })
  }

  async getFeedList({ date } = {}) {
    const url = `${feed_url}/${date.toISOString()}`
    const res = await http.get(url)
    const list = res.body || []

    const feedList = list.map((it) => toFeed(it))
    runInAction(() => {
      state.list = feedList
    })
  }
}

const feed = new Feed()
export default feed
