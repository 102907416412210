import { runInAction } from 'mobx'

import { http, timer, leaveBalance } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const DateFormat = 'MMYY'
let state
export class LeaveBalance extends BaseStore {
  constructor() {
    super()
    this.observable({
      balance_date: undefined,
      balance_list: [],
      next: {
        balance_date: undefined,
        balance_list: [],
      },
    })

    state = this
  }

  async getBalanceList(store, { balance_date, force } = {}) {
    const txt = balance_date.toISOString()

    if (!force && store.balance_date) {
      const current = store.balance_date.format(DateFormat)
      const next = balance_date.format(DateFormat)
      if (current === next) {
        return store.balance_list
      }
    }

    const url = `${config.api}/v1/user/leave-request/leave-balance/${txt}`
    const res = await http.get(url)

    const list = res.body || []

    return list
  }

  async getLeaveBalance({ date, force } = {}) {
    const balance_date = date ? date : timer.get()
    const store = this.toJS()
    const list = await this.getBalanceList(store, { balance_date, force })

    runInAction(() => {
      state.balance_date = balance_date
      state.balance_list = list
    })

    return list
    /*
    const balance_date = date ? date : timer.get()
    const txt = balance_date.toISOString()

    const store = this.toJS()
    if (!force && store.balance_date) {
      const DateFormat = 'MMYY'
      const current = store.balance_date.format(DateFormat)
      const next = balance_date.format(DateFormat)
      if (current === next) {
        console.log('reuse')
        return store.balance_list
      }
    }

    const url = `${config.api}/v1/user/leave-request/leave-balance/${txt}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.balance_date = balance_date
      state.balance_list = list
    })

    return list
    */
  }

  async getNextLeaveBalance({ date, force } = {}) {
    const balance_date = date ? date : timer.get()
    const store = this.toJS().next
    const list = await this.getBalanceList(store, { balance_date, force })

    runInAction(() => {
      state.next = {
        balance_date,
        balance_list: list,
      }
    })

    return list
    /*
    const balance_date = date ? date : timer.get()
    const txt = balance_date.toISOString()

    const store = this.toJS().next
    if (!force && store.balance_date) {
      const DateFormat = 'MMYY'
      const current = store.balance_date.format(DateFormat)
      const next = balance_date.format(DateFormat)
      if (current === next) {
        console.log('reuse')
        return store.balance_list
      }
    }

    const url = `${config.api}/v1/user/leave-request/leave-balance/${txt}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.next = {
        balance_date,
        balance_list: list,
      }
    })

    return list
    */
  }

  async getAvailableBalance({ leave_config, leave_req, leave_type } = {}) {
    const { isSame } = leaveBalance.isSameBalance({
      leave_config,
      leave_req,
      leave_type,
    })

    const { start_date } = leave_req
    if (leave_req.leave_type === 'one' || isSame) {
      const balance_list = await this.getLeaveBalance({
        date: start_date,
      })

      const balance_day = leaveBalance.getAvailableBalance(
        balance_list,
        leave_req,
        leave_type
      )
      return balance_day
    } else {
      /*
      const [current_list, next_list] = await Promise.all([
        this.getLeaveBalance({ date: start_date })
        this.getNextLeaveBalance({ date: end_date })
      ])

      const current_day = leaveBalance.getAvailableBalance(
        current_list,
        leave_req,
        leave_type
      )

      const next_day = leaveBalance.getAvailableBalance(
        next_list,
        leave_req,
        leave_type
      )
      */
      return undefined
    }
  }
}

export default new LeaveBalance()
