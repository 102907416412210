/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#494949' } = props
  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7454 14.589C11.7431 14.589 11.7409 14.589 11.7386 14.589C11.3246 14.5853 10.9916 14.2466 10.9954 13.8323L10.9988 13.4573C10.9988 13.4359 10.9999 13.4141 11.0021 13.3928C11.106 12.3019 11.8301 11.6333 12.4125 11.0959C12.6098 10.9136 12.7965 10.7415 12.9555 10.5619C13.1494 10.3433 13.4306 9.89551 13.1348 9.35513C12.7939 8.73113 11.9614 8.55451 11.3145 8.70263C10.6388 8.85713 10.3898 9.43426 10.3013 9.76426C10.194 10.1644 9.78263 10.4018 9.3825 10.2949C8.98237 10.1876 8.745 9.77626 8.85187 9.37613C9.15075 8.25976 9.92625 7.48163 10.9793 7.24051C12.3964 6.91688 13.8236 7.48951 14.4503 8.63513C14.9719 9.58913 14.829 10.7085 14.0771 11.5568C13.8683 11.7923 13.6451 11.9985 13.4291 12.1976C12.891 12.6945 12.5546 13.0268 12.4976 13.5064L12.4946 13.845C12.4916 14.2579 12.1564 14.589 11.7454 14.589Z"
        fill={color}
      />
      <path
        d="M11.745 16.8375C11.55 16.8375 11.355 16.7588 11.2163 16.62C11.0738 16.4813 10.995 16.2863 10.995 16.0875C10.995 15.8925 11.0738 15.6975 11.2163 15.5588C11.4938 15.2813 11.9963 15.2813 12.2738 15.5588C12.4163 15.6975 12.495 15.8925 12.495 16.0875C12.495 16.2863 12.4163 16.4775 12.2775 16.62C12.135 16.7588 11.9438 16.8375 11.745 16.8375Z"
        fill={color}
      />
      <path
        d="M11.9711 21.7489C11.118 21.7489 10.2724 21.6379 9.45751 21.4185C5.94601 20.4739 3.19651 17.565 2.45289 14.0074C1.69502 10.383 3.09377 6.573 6.01689 4.30088C7.70664 2.98725 9.83776 2.2635 12.018 2.2635C13.5274 2.2635 15.0263 2.6145 16.353 3.27788C19.6294 4.917 21.7463 8.34151 21.7463 12.0026C21.7463 14.9899 20.3366 17.8691 17.9756 19.7044C16.2799 21.0229 14.1473 21.7489 11.9711 21.7489ZM12.0184 3.7635C10.17 3.7635 8.36551 4.37475 6.93789 5.48513C4.46401 7.40813 3.27976 10.6328 3.92139 13.7003C4.55064 16.7096 6.87676 19.1708 9.84751 19.9699C10.5353 20.1548 11.25 20.2489 11.9711 20.2489H11.9715C13.8158 20.2489 15.621 19.635 17.0554 18.5201C19.0538 16.9669 20.2466 14.5305 20.2466 12.0026C20.2466 8.90476 18.4553 6.00675 15.6825 4.61963C14.5628 4.05975 13.296 3.7635 12.0184 3.7635Z"
        fill={color}
      />
    </svg>
  )
}

/* eslint-enable */
export default Logo
