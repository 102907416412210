import { lazy, Suspense } from 'react'
import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import Loading from 'components/loading'

const LoginPage = lazy(() => import('pages/login'))
const Dashboard = lazy(() => import('pages/dashboard'))

const SetPasswordPage = lazy(() => import('pages/password/setPassword'))
const BasicInformation = lazy(() => import('pages/profile/BasicInformation'))
const PrivacyInformation = lazy(
  () => import('pages/profile/PrivacyInformation')
)
const CompanyProfile = lazy(() => import('pages/company'))
const DocumentList = lazy(() => import('pages/document'))
const PendingList = lazy(() => import('pages/pending'))
const Holiday = lazy(() => import('pages/holiday'))
const ReimbursementApp = lazy(() => import('Reimbursement'))
const AttendanceApp = lazy(() => import('AttendanceApp'))
const LeaveApp = lazy(() => import('LeaveApp'))
const PayrollApp = lazy(() => import('PayrollApp'))
const OvertimeApp = lazy(() => import('OvertimeApp'))
const FeedApp = lazy(() => import('FeedApp'))
const TimesheetApp = lazy(() => import('TimesheetApp'))

const UserApp = (props) => {
  const isLogin = props.member.isLogin()
  if (!isLogin) {
    return (
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/*" element={<LoginPage />} />
        </Routes>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/set-password" element={<SetPasswordPage />} />
        <Route path="/profile" element={<BasicInformation />} />
        <Route path="/profile-privacy" element={<PrivacyInformation />} />
        <Route path="/company" element={<CompanyProfile />} />
        <Route path="/document" element={<DocumentList />} />
        <Route path="/pending" element={<PendingList />} />
        <Route path="/holiday" element={<Holiday />} />
        <Route path="/leave-req//*" element={<LeaveApp />} />
        <Route path="/attendance/*" element={<AttendanceApp />} />
        <Route path="/reimbursement/*" element={<ReimbursementApp />} />
        <Route path="/payroll/*" element={<PayrollApp />} />
        <Route path="/overtime/*" element={<OvertimeApp />} />
        <Route path="/feed/*" element={<FeedApp />} />
        <Route path="/timesheet/*" element={<TimesheetApp />} />
      </Routes>
    </Suspense>
  )
}

export default inject('member')(observer(UserApp))
