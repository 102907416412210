import styled from 'styled-components'

import { Down } from 'icon'

const OpenBtn = (props) => {
  const { onClick } = props

  return (
    <Button onClick={onClick}>
      <Down color="white" size={0.7} />
    </Button>
  )
}

const Button = styled.div`
  width: 40px;
  height: 40px;
  color: white;
  background-color: ${(p) => p.theme.color_level.grey.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 29px;
  cursor: pointer;

  span {
    margin-right: 4px;
    font-size: 16px;
  }
`
export default OpenBtn
