import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import '@mdxeditor/editor/style.css'

import ScrollToTop from 'components/ScrollToTop'
import theme from 'theme'
import store from 'stores'

import './assets'
import App from './App'
import UserApp from './UserApp'

ReactDOM.render(
  <Provider {...store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="*" element={<App />} />
            <Route path="/user/*" element={<UserApp />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// reportWebVitals()
