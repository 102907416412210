import numeral from 'numeral'
import moment from 'moment'

export const isNull = (val) => {
  if (val === undefined || val === null || val === '') return true
  else return false
}

export const toInt = (val, format = '0') => {
  if (isNull(val) || isNaN(+val)) return format
  else return numeral(val).format(format)
}

export const toDigi = (val) => {
  if (isNull(val) || isNaN(+val)) return '0'
  else return numeral(val).format('0,0')
}

export const precision = (val) => {
  return Math.floor(val * 100) / 100
}

export const toMoney = (val) => {
  if (isNull(val) || isNaN(+val)) return '0'
  else return numeral(val).format('0,0.00')
}

export const toText = (val) => {
  if (isNull(val)) return '-'
  else return val
}

export const toDatetime = (val, { isThai } = {}) => {
  if (isNull(val)) return '-'

  const mm = moment(val)
  const date = mm.format('DD/MM')
  const year = mm.year()
  const time = mm.format('HH:mm')
  if (isThai) {
    return `${date}/${year + 543} เวลา ${time} น.`
  }

  return `${date}/${year} time ${time}`
}

export const toTime = (val) => {
  if (isNull(val)) return '-'

  const mm = moment(val)
  return mm.format('HH:mm')
}

export const toUsername = (user = {}) => {
  const name = user.name || ''
  const nickname = user.nickname || ''

  return nickname === '' ? name : `${name} (${nickname})`
}

export const upperText = (text = '') => {
  if (text.length === 0) return text
  return text[0].toUpperCase() + text.substring(1)
}

export const toNameDay = (val, { isThai } = {}) => {
  if (isNull(val)) return '-'

  const day = moment(val).day()
  return isThai ? TH_DAY[day] : DAY[day]
}

const getMonth = (isShort, isThai) => {
  if (isShort) {
    return isThai ? SHORT_TH_MONTH : SHORT_MONTH
  } else {
    return isThai ? TH_MONTH : MONTH
  }
}

export const toDate = (val, { format, isShort = true, isThai } = {}) => {
  if (isNull(val)) return ''

  const date = moment(val, format)
  const m = date.month()
  const y = date.year()

  const month = getMonth(isShort, isThai)
  const year = isThai ? y + 543 : y
  return `${date.date()} ${month[m]} ${year}`
}

export const toFullDate = (val, { format, isShort = true, isThai } = {}) => {
  if (isNull(val)) return ''

  const date = moment(val, format)
  const dayOfWeek = date.day()

  const day = isThai ? SHORT_TH_DAY[dayOfWeek] : SHORT_DAY[dayOfWeek]
  const txt = toDate(val, { format, isShort, isThai })
  return `${day}, ${txt}`
}

export const toMonth = (val, { format, isShort, isThai } = {}) => {
  if (isNull(val)) return ''

  const date = moment(val, format)
  const m = date.month()
  const y = date.year()

  const month = getMonth(isShort, isThai)
  const year = isThai ? y + 543 : y
  return `${month[m]} ${year}`
}

export const toDateNoYear = (val, { format, isShort = true, isThai } = {}) => {
  if (isNull(val)) return ''

  const date = moment(val, format)
  const m = date.month()

  const month = getMonth(isShort, isThai)
  return `${date.date()} ${month[m]}`
}

export const toPattern = (value, pattern) => {
  if (!value) return ''

  let valueIndex = 0
  const list = String(pattern).split('')
  const newList = []

  for (let i = 0; i < pattern.length && valueIndex < value.length; i++) {
    if (list[i] === 'x') {
      newList.push(value[valueIndex++])
    } else {
      newList.push(list[i])
    }
  }

  return newList.join('')
}

export const clearPattern = (text = '', ch = '-') => {
  const value = (text || '').replaceAll(ch, '')

  return value
}

export const toMobile = (text) => {
  const mobile = (text || '').replaceAll('-', '')
  return toPattern(mobile, 'xxx-xxx-xxxx')
}

export const replaceAll = (text, ch, to = '') => {
  const val = `${text || ''}`
  const c = `${ch || ''}`

  return val.replaceAll(c, to)
}

export const getWorkTime = (val = 0) => {
  if (val <= 0) return { hour: 0, minute: 0 }

  const hour = Math.trunc(val / 60)
  const minute = Math.trunc(val % 60)

  return { hour, minute }
}

export const toWorkTime = (val = 0, { isThai } = {}) => {
  const h = isThai ? 'ชม' : 'h'
  const m = isThai ? 'น' : 'm'

  if (val <= 0) return `0${h}`

  const { hour, minute } = getWorkTime(val)
  const hh = toInt(hour, '0')
  const mm = toInt(minute, '00')
  const hTxt = `${hh}${h}`
  const tTxt = `${mm}${m}`
  if (hour > 0 && minute > 0) {
    return `${hTxt}:${tTxt}`
  }

  if (hour > 0) return hTxt
  return tTxt
}

export const toWorkingHour = (val) => {
  const m = +val || 0
  const { hour, minute } = getWorkTime(m)

  return `${hour}:${numeral(minute).format('00')}`
}

export const toManday = (val = 0, { isThai, per_day = 8 } = {}) => {
  const d = isThai ? 'วัน' : 'd'
  const h = isThai ? 'ชม' : 'h'
  const m = isThai ? 'น' : 'm'

  if (val <= 0) return `0${h}`

  const { hour, minute } = getWorkTime(val)
  const day = Math.trunc(hour / per_day)
  const hour2 = Math.trunc(hour % per_day)

  const dd = toInt(day, '0')
  const hh = toInt(hour2, '0')
  const mm = toInt(minute, '00')

  const dTxt = `${dd}${d}`
  const hTxt = `${hh}${h}`
  const tTxt = `${mm}${m}`
  const list = []
  if (day > 0) {
    list.push(dTxt)
  }

  if (hour2 > 0) {
    list.push(hTxt)
  }

  if (minute > 0) {
    list.push(tTxt)
  }

  if (list.length === 0) return ''
  return list.join(':')
}

const DAY = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday',
}

const TH_DAY = {
  1: 'จันทร์',
  2: 'อังคาร',
  3: 'พุธ',
  4: 'พฤหัสบดี',
  5: 'ศุกร์',
  6: 'เสาร์',
  0: 'อาทิตย์',
}

const SHORT_DAY = {
  1: 'Mon',
  2: 'Tues',
  3: 'Wed',
  4: 'Thurs',
  5: 'Fri',
  6: 'Sat',
  0: 'Sun',
}

const SHORT_TH_DAY = {
  1: 'จ',
  2: 'อ',
  3: 'พ',
  4: 'พฤ',
  5: 'ศ',
  6: 'ส',
  0: 'อา',
}

const SHORT_MONTH = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

const SHORT_TH_MONTH = {
  0: 'ม.ค.',
  1: 'ก.พ.',
  2: 'มี.ค.',
  3: 'ม.ย.',
  4: 'พ.ค.',
  5: 'มิ.ย.',
  6: 'ก.ค.',
  7: 'ส.ค.',
  8: 'ก.ย.',
  9: 'ต.ค.',
  10: 'พ.ค.',
  11: 'ธ.ค.',
}

const MONTH = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
}

const TH_MONTH = {
  0: 'มกราคม',
  1: 'กุมภาพันธ์',
  2: 'มีนาคม',
  3: 'เมษายน',
  4: 'พฤษภาคม',
  5: 'มิถุนายน',
  6: 'กรกฎาคม',
  7: 'สิงหาคม',
  8: 'กันยายน',
  9: 'ตุลาคม',
  10: 'พฤศจิกายน',
  11: 'ธันวาคม',
}
